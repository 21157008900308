import React, { useEffect, useState } from 'react';

import logo from './sai-baba.png';
import './App.css';

function App() {

  const [data, setData] = useState(null);

  function handleAnswer() {
    fetch('/api/answer')
      .then(response => response.json())
      .then(data => (setData(data)));
  }

  useEffect(() => {
    fetch('/api/home')
      .then(response => response.json())
      .then(data => (setData(data)));
  }, [])
  

  return (
    <div className="page-container">
      <div className="content-wrap">
        <div style={{ "float": "left" }}>
          <span className='blink_me'>Shraddha</span>
        </div>
        <div style={{ "float": "right" }}>
          <span className='blink_me'>Saburi</span>
        </div>
        <div style={{ "float": "none" }}>
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <p className="App-title">
          Your question<br /> Shree Sai Baba's answer
        </p>
        <p className="App-link">
          As is the feeling in one’s mind so is one’s experience
        </p>
        <p className="App-god">Shree Sainath Prasanna</p>
        <p className="App-question">HOW TO SEEK ANSWERS FOR YOUR QUESTIONS?</p>
        <p className="App-quote">
          Assume that you have visited Shree Sainath Maharaj at Shirdi, uttering words of Shree Sai. <br />Keep the
          question in mind thinking that Shree Sainath Maharaj shall answer and then click
        </p>
        <button style={{ "height": "36px", "width": "150px" }} onClick={() => handleAnswer()}>Answer Me Baba</button>
        <div className="App-answer"><p>{data && data.answer && data.answer}</p></div>

      </div>
      <div className="footer">
        <p className="text-muted">Please experience these answers and do not take it as fun.</p>
        <p>{data && data.count && data.count !== 0 ? data.count + " questions answered here and still counting" : null}</p>
      </div>
    </div>
  );
}

export default App;
